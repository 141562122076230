import styled from 'styled-components';
import { device } from '../../utils/styles';

export const LogoImg = styled.img`
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;

    @media ${device.mobileL} {
      width: 250px;
    }

    @media ${device.tablet} {
      top: 10px;
      width: 300px;
    }

    @media ${device.laptop} {
      left: 45px;
      transform: none;
    }

    @media ${device.laptopL} {
      left: 80px;
    }
`;