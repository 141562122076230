import styled from "styled-components"
import { device } from "../../utils/styles"

export const SocialLinks = styled.ul`
  display: none;
  position: absolute;
  bottom: 54px;
  left: 0;
  opacity: 0.9;

  @media ${device.laptopL} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 135px;
    width: 54px;
  }

  img {
    width: 23.5px;
  }
`
