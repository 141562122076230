import styled from "styled-components"
import { fonts, device, colors, limiter } from "../utils/styles"

export const Home = styled.section`
  position: relative;
  padding-top: 110px;

  @media ${device.mobileL} {
    padding: 45px;
  }

  @media ${device.laptop} {
    padding: 54px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media ${device.laptopL} {
    padding-left: 80px;
  }
`

export const ImageWrapper = styled.figure`
  max-width: 560px;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    transition: all 1.4s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: ${colors.mainWhite};
    animation: slideIn 1.3s forwards 0.2s;
  }

  @media ${device.mobileL} {
    margin: 0 auto;
    margin-top: 100px;
  }

  @media ${device.laptop} {
    margin-top: 0;
    margin: 0;
    min-width: 38%;
    max-height: calc(100vh - 108px);
    animation: slideIn 1.3s forwards 0.9s;
    /* overflow: hidden; */
  }

  @keyframes slideIn {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }
`

export const MainHeader = styled.header`
  padding: 0 1.05em;
  position: relative;

  &:after {
    content: "";
    transition: all 1.4s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: ${colors.mainWhite};
    animation: slideIn 1.3s forwards 0.2s;
  }

  @media ${device.mobileL} {
    padding: 0;
    max-width: 560px;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    max-width: inherit;
    padding: 0 0.7em;
    margin: 0;
    margin-top: 6.4em;
  }

  @media ${device.laptopL} {
    margin-top: 8em;
  }

  a {
    margin-top: 2.2em;

    @media ${device.laptop} {
      margin-top: 1em;
    }

    @media ${device.laptopL} {
      margin-top: 2.5em;
    }
  }
`

export const MainTitle = styled.h1`
  font-size: calc(0.98rem + 2.2vw);
  line-height: calc(1.5rem + 2.2vw);
  padding-top: 0.4em;
  font-weight: ${fonts.fontBold};
  letter-spacing: 0.07em;

  @media ${device.laptop} {
    padding-top: 0.2em;
    font-size: calc(0.84rem + 3.35vw);
    line-height: calc(1.6rem + 3.25vw);
  }

  @media ${device.laptop} {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -33px;
      left: 5px;
      background: ${colors.mainBlack};
      width: 70px;
      height: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -33px;
      left: 96px;
      background: ${colors.mainBlack};
      width: 20px;
      height: 20px;
    }
  }

  @media ${device.laptopL} {
    padding-top: 0;
    font-size: calc(0.88rem + 3.85vw);
    line-height: calc(1.5rem + 3.85vw);
  }

  @media (min-width: 1780px) {
    font-size: 5.5em;
    line-height: 6.5rem;
  }

  @media (min-width: 1919px) {
    font-size: 5.85em;
    line-height: 6.7rem;
  }
`

export const MainDescription = styled.p`
  font-weight: ${fonts.fontLight};
  font-size: 0.93rem;
  margin-top: 1.1em;
  padding-top: 0.4em;
  line-height: 1.75rem;
  letter-spacing: 0.03em;

  @media ${device.tablet} {
    line-height: 1.7rem;
    max-width: 580px;
  }

  @media ${device.laptop} {
    margin-top: 4.1em;
    padding-top: 1em;
  }

  @media ${device.laptopL} {
    font-size: 1rem;
    line-height: 1.85rem;
    margin-left: 9.8em;
    margin-top: 7em;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      background: ${colors.gray};
      width: 50px;
      height: 2px;
    }
  }

  @media (min-width: 1780px) {
    font-size: 1.1rem;
    line-height: 1.95rem;
    max-width: 650px;
    margin-left: 11.2em;
    margin-top: 6.3em;
  }
`

export const MainDivider = styled.span`
  display: block;
  margin-top: 2em;

  @media ${device.laptop} {
    margin-top: 5.5em;
  }

  @media ${device.laptopL} {
    margin-top: 8em;
  }
`

export const Content = styled.section`
  ${limiter};
`

export const Header = styled.header`
  width: 100%;
  margin-top: 7em;

  @media ${device.tablet} {
    margin-top: 9em;
  }

  @media ${device.laptop} {
    margin-top: 12em;
  }

  /* @media ${device.laptopL} {
    margin-top: 25em;
  } */
`

export const Intro = styled.p`
  font-size: 0.63rem;
  font-weight: ${fonts.fontRegular};
  text-transform: uppercase;
  position: relative;
  margin-bottom: 2.8em;

  @media ${device.tablet} {
    font-size: 0.7rem;
  }

  @media ${device.laptop} {
    font-size: 0.95rem;
  }

  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 1.6em;
    left: 0;
    width: 170px;
    height: 1px;
    background: ${colors.mainBlack};

    @media ${device.laptop} {
      width: 280px;
    }
  }
`

export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1.25rem;
  margin-bottom: 0.4em;

  @media ${device.tablet} {
    font-size: 1.85rem;
  }

  @media ${device.laptop} {
    font-size: 2.75rem;
  }

  @media ${device.laptopL} {
    font-size: 3.2rem;
  }
`

export const Description = styled.p`
  font-size: 0.93rem;
  line-height: 1.72rem;
  font-weight: ${fonts.fontLight};
  margin-bottom: 1.6em;

  @media ${device.tablet} {
    font-size: 1.17rem;
    line-height: 2.1rem;
  }

  /* @media ${device.laptop} {
    font-size: 1.3rem;
    line-height: 2.2rem;
  } */
`

export const Divider = styled.span`
  display: block;
  margin-top: 2em;

  @media ${device.tablet} {
    margin-top: 2.6em;
  }

  @media ${device.laptopL} {
    margin-top: 3.5em;
  }
`

export const ArtGrid = styled.div`
  width: 100%;
  margin-top: 2.5em;
  margin-bottom: 6em;

  @media ${device.tablet} {
    margin-top: 3.4em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.7em;
    grid-row-gap: 2em;
    margin-bottom: 8em;
  }

  @media ${device.laptop} {
    margin-top: 4.5em;
    grid-column-gap: 3.8em;
    grid-row-gap: 1.6em;
    margin-bottom: 11em;
  }
`

export const ArtImageWrapper = styled.figure`
  overflow: hidden;
`

export const ArtImage = styled.img`
  transition: all 0.2s ease-in-out;
  height: 100%;
`

export const ArtDescription = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.4em;
  transition: opacity 0.2s ease-in-out;
  color: ${colors.mainBlack};

  @media ${device.laptop} {
    opacity: 0;
  }
`

export const Art = styled.article`
  margin-bottom: 1.8em;

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    &:hover ${ArtImage} {
      transform: scale(1.07);
      filter: grayscale(100%);
    }

    &:hover ${ArtDescription} {
      opacity: 1;
    }
  }
`

export const ArtTitle = styled.h3`
  font-size: 0.9em;
  font-weight: ${fonts.fontLight};
  flex: 0.65;
  padding-right: 0.2em;

  @media ${device.laptop} {
    font-size: 1.05rem;
  }
`

export const ArtDate = styled.p`
  font-size: 0.72em;
  font-weight: ${fonts.fontLight};
  flex: 0.35;
  text-align: end;

  @media ${device.laptop} {
    font-size: 0.82rem;
  }
`

// WARSZTATY

export const WorkShopsWrapper = styled.div`
  /* background: ${colors.lightGray}; */
  /* padding: 0.65em; */

  /* @media ${device.tablet} {
    padding: 1em;
  }

  @media ${device.laptop} {
    padding: 2.8em;
  } */
`

export const WorkShopsTitle = styled.h3`
  text-transform: uppercase;
  font-size: 1.1rem;
  margin-top: 1em;
  margin-bottom: 1.7em;
  position: relative;

  @media ${device.tablet} {
    font-size: 1.55rem;
  }

  @media ${device.laptop} {
    font-size: 2.15rem;
  }

  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 1.6em;
    left: 0;
    width: 60px;
    height: 1px;
    background: ${colors.mainBlack};

    @media ${device.laptop} {
      width: 100px;
    }
  }
`

export const WorkShops = styled.div`
  /* display: flex; */
  /* flex-direction: column-reverse; */
  margin-top: 5em;
  /* padding-bottom: 1.4em; */

  /* @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    margin-top: 3.6em;
  }

  @media ${device.laptop} {
    margin-top: 6em;
  } */
`

// export const WorkShopsImage = styled.img`
//   transition: all 0.2s ease-in-out;
// `

// export const WorkShopsImageWrapper = styled.figure`
//   overflow: hidden;

//   @media ${device.tablet} {
//     flex: 0.45;
//   }

//   @media ${device.laptop} {
//     padding-left: 2.7em;
//   }

//   &:hover ${WorkShopsImage} {
//     filter: grayscale(100%);
//   }
// `

// export const WorkShopsContent = styled.div`
//   margin-top: 1.3em;

//   @media ${device.tablet} {
//     flex: 0.55;
//     margin-top: 0;
//   }

//   @media ${device.laptopL} {
//     margin-bottom: 2.7em;
//   }
// `

// export const WorkShopsName = styled.h3`
//   font-size: 1.1rem;
//   margin-bottom: 0.4em;

//   @media ${device.tablet} {
//     margin-bottom: 1.1em;
//   }
// `

// export const WorkShopsDescription = styled.p`
//   font-size: 0.92rem;
//   line-height: 1.67rem;
//   font-weight: ${fonts.fontLight};
//   max-width: 540px;

//   @media ${device.tablet} {
//     font-size: 0.92rem;
//     line-height: 1.5rem;
//     font-size: 1rem;
//     line-height: 1.6rem;
//     padding-right: 3em;
//   }

//   @media ${device.laptop} {
//     font-size: 1.2rem;
//     line-height: 1.9rem;
//     padding-right: 4.4em;
//   }
// `
