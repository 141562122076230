import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"
import PageTransition from "gatsby-plugin-page-transitions"
import Loadable from "react-loadable"

import {
  Home,
  ImageWrapper,
  MainHeader,
  MainTitle,
  MainDescription,
  Content,
  Header,
  Intro,
  Title,
  Description,
  Divider,
  ArtGrid,
  Art,
  ArtDescription,
  ArtImageWrapper,
  ArtImage,
  ArtTitle,
  ArtDate,
  // WorkShopsWrapper,
  WorkShops,
  WorkShopsTitle,
  // WorkShopsImageWrapper,
  // WorkShopsImage,
  // WorkShopsContent,
  // WorkShopsName,
  // WorkShopsDescription,
} from "../sections/index.styles"

import imgOne from "../assets/przemoc-pamiec.png"
import imgTwo from "../assets/radio-zelaza.png"
import imgThree from "../assets/reality-disappears-after-walking.png"

import ShowcaseImage from "../components/ShowcaseImage/ShowcaseImage"
import SEO from "../components/seo"
import Logo from "../components/Logo/Logo"
import Social from "../sections/index/Social"
import NavLink from "../components/NavLink/NavLink"
import Spinner from "../components/Video/Spinner"

const LoadableVideo = Loadable({
  loader: () => import("../sections/index/LazyVideo"),
  loading: Spinner,
})

const IndexPage = () => (
  <>
    <SEO
      title="Teatr Dzikistyl Company - spektakle, pokazy, warsztaty"
      keywords={[
        `Teatr Dzikistyl Company`,
        `Dzikistyl Company`,
        `Patryk Gacki`,
        `Patryk Dariusz Gacki`,
        `Wioleta Fiuk`,
        `taniec współczesny`,
        `taniec`,
        `teatr`,
        `Gdańsk warsztaty taneczne`,
      ]}
      description="Dzikistyl Company to teatr założony w 2010 roku w Gdańsku. Członkowie: Wioleta Fiuk, Patryk Gacki."
    />
    <PageTransition>
      <Home>
        <Social />
        <Logo />
        <ImageWrapper>
          <ShowcaseImage />
        </ImageWrapper>
        <MainHeader>
          <MainTitle>
            Teatr
            <br />
            DzikiStyl Compa<span>ny</span>
          </MainTitle>
          <MainDescription>
            Dzikistyl Company to teatr, który powstał w 2010 roku w Gdańsku.
            Założycielami są Wioleta Fiuk i Patryk Gacki, zawodowi tancerze i
            choreografowie o bogatym dorobku artystycznym.
            <NavLink to="/kontakt">Kontakt</NavLink>
          </MainDescription>
        </MainHeader>
      </Home>
      <Content>
        <Fade bottom>
          <Header>
            <Intro>O teatrze</Intro>
            <Title>Kim jesteśmy</Title>
          </Header>
          <Description>
            Teatr Dzikistyl Company poszukuje równowagi między tańcem fizycznym,
            a ruchem codziennym, między odrębnymi technikami tanecznymi oraz
            kontekstów, w których te połączenia zafunkcjonują. Interesuje nas
            ruch jako świadoma kompozycja. To twórczość kompilacyjna, łącząca
            różne elementy i treści z różnych stylów tańca (balet, taniec
            współczesny, breaking) i kierunków artystycznych. Tworzymy w
            obszarach teatru tańca, teatru dramatycznego, musicalu oraz tańca
            rozrywkowego. Szerokiej publiczności znani jesteśmy jako zwycięzcy
            programów telewizyjnych You Can Dance i Got to Dance.
            <br />
            <br />
            Na co dzień związani jesteśmy z Teatrem Muzycznym w Gdyni, gdzie
            można nas zobaczyć w takich spektaklach jak Notre Dame de Paris i
            Wiedźmin. Opracowujemy również ruch sceniczny do spektakli
            dramatycznych w całej Polsce. Współpracowaliśmy z Adamem Nalepą
            (Nasza Klasa, Nie-Boska Komedia, Czarownice z Salem, Kupiec Wenecki,
            Zemsta, Krwawe Gody), Jakubem Roszkowskim( Wielka Improwizacja,
            Lepszy Świat, Bunt), Adamem Orzechowskim (Mary Page Marlow),
            Zbigniewem Brzozą (Do komina, murzyna, murzyna), Michałem Derlatką (
            Zaczarowana Królewna), Radkiem Stępniem (Śmierć Komiwojarzera).
            Oprócz działalności artystycznej prowadzimy działalność edukacyjną w
            Teatrze Muzycznym w Gdyni.
          </Description>
          <Divider />
          <NavLink to="/spektakle">Więcej</NavLink>
        </Fade>
        <Fade bottom>
          <Header>
            <Intro style={{ color: "#fff" }}>.</Intro>
            <Title>Nasza działalność</Title>
          </Header>
          <Description>
            Mamy w swojej ofercie bogaty zbiór różnego rodzaju działań
            artystycznych oraz spektakli teatralnych. Współpacujemy z wieloma
            trójmiejskimi scanami m. in. Teatr Wybrzeże w Gdańsku, Teatr
            Muzyczny w Gdyni, Teatr Miniatura, Klub Żak.
          </Description>
          <Divider />
          <NavLink to="/spektakle">Zobacz</NavLink>
        </Fade>
        <Fade bottom>
          <ArtGrid>
            <Link to="/spektakle/przemoc-pamiec">
              <Art>
                <ArtImageWrapper>
                  <ArtImage src={imgOne} alt="Przemoc Pamięć" />
                </ArtImageWrapper>
                <ArtDescription>
                  <ArtTitle>Przemoc / Pamięć</ArtTitle>
                  <ArtDate>20 styczeń, 2019</ArtDate>
                </ArtDescription>
              </Art>
            </Link>
            <Link to="/spektakle/radio-zelaza">
              <Art>
                <ArtImageWrapper>
                  <ArtImage src={imgTwo} alt="Radio Żelaza" />
                </ArtImageWrapper>
                <ArtDescription>
                  <ArtTitle>Radio Żelaza</ArtTitle>
                  <ArtDate>5 maja, 2015</ArtDate>
                </ArtDescription>
              </Art>
            </Link>
            <Link to="/spektakle/reality-disappears-after-walking">
              <Art>
                <ArtImageWrapper>
                  <ArtImage
                    src={imgThree}
                    alt="Reality disappears after walking"
                  />
                </ArtImageWrapper>
                <ArtDescription>
                  <ArtTitle>Reality disappears after walking</ArtTitle>
                  <ArtDate>19 maja, 2018</ArtDate>
                </ArtDescription>
              </Art>
            </Link>
          </ArtGrid>
        </Fade>
        {/* <WorkShopsWrapper> */}
        <Fade bottom>
          <WorkShopsTitle>Edukacja</WorkShopsTitle>
          <Description>
            Ważnym aspektem naszej pracy zawodowej jest prowadzenie
            stacjonarnych zajęć taneczno-teatralnych i gościnnych warsztatów dla
            dzieci, młodzieży i osób dorosłych.
          </Description>
          <NavLink to="/edukacja">Zobacz</NavLink>
        </Fade>
        <Fade bottom>
          <WorkShops>
            <LoadableVideo />
            {/* <WorkShopsContent>
                <WorkShopsName>Dziki Junior</WorkShopsName>
                <WorkShopsDescription>
                  Wielopłaszczyznowy projekt cotygodniowych warsztatów
                  taneczno-teatralnych dla dzieci, młodzieży i osób dorosłych,
                  który zakończony jest prezentacją widowiska tanecznego na
                  scenie Teatru Muzycznego w Gdyni.
                </WorkShopsDescription>
                <Divider />
                <NavLink to="/edukacja">Zobacz</NavLink>
              </WorkShopsContent>
              <WorkShopsImageWrapper>
                <Link to="/edukacja">
                  <WorkShopsImage
                    src={workShopsImg}
                    alt="Dziki Junior - Teatr Muzyczny im. Danuty Baduszkowej w Gdyni"
                  />
                </Link>
              </WorkShopsImageWrapper> */}
          </WorkShops>
        </Fade>
        {/* </WorkShopsWrapper> */}
      </Content>
      <Divider />
    </PageTransition>
  </>
)

export default IndexPage
