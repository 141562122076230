import styled from "styled-components"
import Image from "gatsby-image"

export const Img = styled(Image)`
  transform: scale(1.4);
  transition: transform 1.3s ease-in-out;
  animation: zoomOut 1.3s forwards 0.2s;

  @keyframes zoomOut {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
`
