import React from "react"
import { SocialLinks } from "./Social.styles"

import fbIcon from "../../assets/icons/facebook-icon.png"
import instIcon from "../../assets/icons/instagram-icon.png"
import ytIcon from "../../assets/icons/youtube-icon.png"

const Social = () => (
  <SocialLinks>
    <li>
      <a
        href="https://pl-pl.facebook.com/DzikiStyl"
        title="Profil na Facebooku"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={fbIcon} alt="facebook" />
      </a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/dzikistyl_company/"
        title="Profil na Instagramie"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={instIcon} alt="instagram" />
      </a>
    </li>
    <li>
      <a
        href="https://www.youtube.com/user/dzikistylcrew"
        title="Kanał na Youtube"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={ytIcon} alt="youtube" />
      </a>
    </li>
  </SocialLinks>
)

export default Social
